import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { firebaseApp } from "../../Config/FirebaseConfig";
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Logo from "../../Assets/newlogo.png";
import { AiOutlineHome } from "react-icons/ai";

const auth = getAuth(firebaseApp);

function PasswordReset() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    if (!email || email.length < 5) {
      NotificationManager.error("Lütfen geçerli bir e-posta adresi girin.", "Hata");
      return;
    }
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      NotificationManager.success(
        "Şifre sıfırlama bağlantısı e-posta adresinize gönderildi.",
        "Başarılı"
      );
      setEmail(""); // E-posta alanını sıfırlama
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        NotificationManager.error(
          "Bu e-posta adresiyle kayıtlı bir kullanıcı bulunamadı.",
          "Hata"
        );
      } else {
        NotificationManager.error(
          "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.",
          "Hata"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full">
      <div className="relative hidden sm:block" style={{ backgroundImage: `url(${Logo})`, backgroundSize: "cover", backgroundPosition: "center" }}>
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg flex flex-col items-center justify-center">
          <img src={Logo} alt="logo" className="w-60" />
          <div className="my-10 border-t h-px w-96"></div>
          <h1 className="text-3xl mx-16 text-center font-extralight text-black">
            Parça üretimimiz, projelerin başarılı bir şekilde gerçekleştirilmesine giden yolunuzdur.
          </h1>
        </div>
      </div>

      <div className="bg-gray-200 flex flex-col justify-center">
        <div className="max-w-[400px] w-full mx-auto rounded-lg bg-[#0e2c66] p-8 px-8">
          <h2 className="text-4xl text-white font-bold text-center">Şifre Sıfırla</h2>

          <div className="flex flex-col text-gray-400 py-2">
            <label>Eposta</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="rounded-lg bg-gray-700 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
              type="email"
              placeholder="E-posta adresinizi girin"
            />
          </div>

          {loading ? (
            <div className="relative w-5 h-5 mx-auto py-4">
              <div
                className="absolute top-0 left-0 w-10 h-10 border rounded-full border-blue-500 animate-spin"
                style={{
                  borderTop: "2px solid transparent",
                  borderBottom: "2px solid transparent",
                }}
              ></div>
            </div>
          ) : (
            <button
              onClick={handlePasswordReset}
              className="w-full my-5 py-2 bg-[#f5640c] shadow-lg shadow-orange-500/50 hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
            >
              Şifre Sıfırla
            </button>
          )}

          <div className="text-center w-full">
            <button
              onClick={() => navigate("/giris")}
              className="text-gray-400 mt-5 text-sm"
            >
              Giriş ekranına dön
            </button>
          </div>
        </div>
        <NotificationContainer />
        <button
          className="fixed text-3xl bottom-4 right-4 w-16 h-16 bg-[#0e2c66] text-white rounded-full shadow-lg flex items-center justify-center"
          onClick={() => navigate("/")}
        >
          <AiOutlineHome />
        </button>
      </div>
    </div>
  );
}

export default PasswordReset;
